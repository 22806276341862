import React from 'react'
import intl from 'react-intl-universal';
import { Container, FormGroup, Label, Input, Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import styles from './contact.module.css';

const Contact = (props) => (
  <div className="page page-regular page-contact">
    <Container>
      <h2>{intl.get('pageContact')}</h2>
      <Container>
        <Row>
          <Col className={styles['contact-column']}>
            <Card>
              <CardBody>
                <CardTitle>{intl.get('contactBooking')}</CardTitle>
                <CardBody>
                  <p>
                    <strong>{intl.get('contactForFrench')}</strong><br />
                    Véronique Plasse<br />
                    450-389-0910<br />
                  </p>
                  <p>
                    <strong>{intl.get('contactForEnglish')}</strong><br />
                    Andrea Beaton<br />
                    514-377-4267<br />
                  </p>
                  <p>
                    <a href="mailto:beatonplasse@gmail.com">beatonplasse@gmail.com</a>
                  </p>
                </CardBody>
              </CardBody>
            </Card>
          </Col>
          <div className="w-100 d-md-none d-block"></div>
          <Col className={styles['contact-column']}>
            <Card>
              <CardBody>
                <CardTitle>{intl.get('contactSubscribeTitle')}</CardTitle>
                <form
                    action="https://beaton-plasse.us19.list-manage.com/subscribe/post?u=2eac1474883dcb742ebf6089e&amp;id=a51812f4eb"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                    noValidate
                  >
                  <FormGroup id="mc_embed_signup">
                    <Label for="mce-EMAIL">{intl.get('contactEmail')}</Label>
                    <Input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                  </FormGroup>
                  <div id="mce-responses" className="clear">
                    <div id="mce-error-response" className="response d-none"></div>
                    <div id="mce-success-response" className="response d-none"></div>
                  </div>
                  <div style={{ position: 'absolute', left: -5000 + 'px' }} aria-hidden="true"><input type="text" name="b_2eac1474883dcb742ebf6089e_a51812f4eb" tabIndex="-1" defaultValue="" /></div>
                  <FormGroup>
                    <input type="submit" value={intl.get('contactSubscribe')} name="subscribe" id="mc-embedded-subscribe" className="btn btn-danger" />
                  </FormGroup>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  </div>
)

export default Contact;
